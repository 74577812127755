import consumer from "./consumer"

consumer.subscriptions.create("MessageChannel", {
  connected() {
    console.log("------------connected to the channel--------------")
  },

  disconnected() {
    console.log("------------disconnected--------------")
  },

  received(data) {
    var id = data.id
    $('#messageCampaign-' + id).append('<div class="contact-item contact-border-bottom"><img src="https://static.sociocs.com/icons/user-default-48.png"><div class="contact-info"><div><span class="number">' + data['conversation'].name + '</span>&nbsp;&nbsp;<span class="text-muted fs-14 small"><span class="text-nowrap">' + data['conversation'].created_at + '</span></span><span class="status">✔</span></div><div class="message">' + data['conversation'].message_text +'</div></div></div>')
    $('.sms_conversation')[0].innerHTML = data['partial_html']
  }
});
